import Domain from './_domain.js'
import { NotInitializedError } from '../exceptions.js'

let taskCardsCaches = {}
const reindexRequests = {} // Needs to be tracked per scope

export default class extends Domain {
  // See scopes#_lockCache
  static _lockCache(scopeCardId) {
    const cache = taskCardsCaches[scopeCardId] || {}
    cache.lockUntil = new Date().getTime() + 3000
    return cache
  }

  static _unlockCache(cache) {
    cache.lockUntil = null
  }

  static async create(data, listId, scopeCardId, t) {
    const cache = this._lockCache(scopeCardId)

    const params = Object.assign({}, data, { idList: listId })
    const taskCard = await $tpu.r.cards.create(params)
    await this.link(taskCard.id, scopeCardId, t)

    this._unlockCache(cache)
    return taskCard
  }

  static async destroy(taskCardId, scopeCardId, t) {
    const cache = this._lockCache(scopeCardId)

    await $tpu.r.cards.destroy(taskCardId)
    await this._removeFromScope(taskCardId, scopeCardId, t)

    this._unlockCache(cache)
  }

  // Same flow as scopes#link
  static async link(taskCardId, scopeCardId, t) {
    await $tpu.r.cards.setData(taskCardId, { [$tpu.keys.crdScopeCardId()]: scopeCardId }, t)

    // Add to the ordering indices. No need to touch updatedAt since we rely on the above.
    const taskCardIds = await $tpu.r.data.get(t, scopeCardId, "shared", $tpu.keys.crdTaskCardIds(), [])
    taskCardIds.push(taskCardId)
    return $tpu.r.cards.setData(scopeCardId, { [$tpu.keys.crdTaskCardIds()]: taskCardIds }, t, true)
  }

  static async _removeFromScope(taskCardId, scopeCardId, t) {
    const taskCardIds = await $tpu.r.data.get(t, scopeCardId, "shared", $tpu.keys.crdTaskCardIds(), [])
    taskCardIds.remove(taskCardId)

    // Pass `disableTouch=false` so it forces UI refresh.
    return $tpu.r.cards.setData(scopeCardId, { [$tpu.keys.crdTaskCardIds()]: taskCardIds }, t, false)
  }

  static async unlink(taskCardId, scopeCardId, t) {
    await $tpu.r.cards.setData(taskCardId, { [$tpu.keys.crdScopeCardId()]: null }, t)

    return this._removeFromScope(taskCardId, scopeCardId, t)

    // await $tpu.r.cards.setData(taskCardId, { [$tpu.keys.crdScopeCardId()]: scopeCardId }, t)

    // // Add to the ordering indices. No need to touch updatedAt since we rely on the above.
    // const taskCardIds = await $tpu.r.data.get(t, scopeCardId, "shared", $tpu.keys.crdTaskCardIds(), [])
    // taskCardIds.push(taskCardId)
    // return $tpu.r.cards.setData(scopeCardId, { [$tpu.keys.crdTaskCardIds()]: taskCardIds }, t, true)
  }

  // See scopes#reindex
  static async _reindex(taskCardIds, scopeCardId, t) {
    // Pass `disableTouch=false` so it forces UI refresh because a card might be deleted outside of
    // Power-Up.
    return $tpu.r.cards.setData(scopeCardId, { [$tpu.keys.crdTaskCardIds()]: taskCardIds }, t, false)
  }

  static _orderTaskCards(taskCards, scopeCard, t) {
    const scopeCardId = scopeCard.id
    let reindex = false
    const array = []
    const taskCardIds = scopeCard.shapeUp.taskCardIds || []
    taskCardIds.forEach((cardId) => {
      const card = taskCards[cardId]
      if (card && card.shapeUp.scopeCardId == scopeCardId) {
        array.push(card)
        delete taskCards[cardId]
      } else {
        reindex = true
      }
    })

    // Attach unindexed scopes to the end.
    Object.values(taskCards).forEach((card) => {
      reindex = true
      array.push(card)
    })

    const reindexRequest = reindexRequests[scopeCardId] = reindexRequests[scopeCardId] || {}
    if (reindex) {
      if (this.requestReindex(reindexRequest, taskCardIds.length, array.length)) {
        console.warn(`Reindexing task cards...`)
        const latestIds = array.map(card => card.id)
        this._reindex(latestIds, scopeCard.id, t)
        reindex = false // Speed up UI refresh
      }
    } else {
      this.cancelReindex(reindexRequest)
    }

    return [reindex, array]
  }

  static $cache(scopeCard, unorderedTaskCards, t) {
    const currentTimestamp = new Date().getTime()
    // See scopes#cache
    const lockedCache = taskCardsCaches[scopeCard.id]
    if (lockedCache && lockedCache.lockUntil > currentTimestamp) {
      console.debug("Returning locked cache")
      return lockedCache
    }

    const [indexInconsistent, taskCards] = this._orderTaskCards(unorderedTaskCards, scopeCard, t)
    // See scopes#cache
    if (indexInconsistent) {
      if (lockedCache) {
        return lockedCache
      }

      // `lockedCache` will be null if the page is still initializing.
      throw new NotInitializedError()
    }

    // This will be false if at least one Task card has incomplete accessory.
    let accessoryComplete = true

    let lastUpdatedAt = scopeCard.shapeUp.updatedAt
    const array = []
    taskCards.forEach((card) => {
      card.shapeUp.list = $tpu.r.lists.find(card.idList)
      card.shapeUp.checklists = $tpu.r.checklists.findAllByCardId(card.id)

      array.push(card)

      if (card.shapeUp.updatedAt > lastUpdatedAt) {
        lastUpdatedAt = card.shapeUp.updatedAt
      }

      if (!card.shapeUp.list || !card.shapeUp.checklists) {
        accessoryComplete = false
      }
    })

    const existingCache = taskCardsCaches[scopeCard.id]
    let latestCache = existingCache
    if (!existingCache || lastUpdatedAt > existingCache._lastUpdatedAt) {
      latestCache = {
        data: array,
        lastRefreshedAt: currentTimestamp,
        _lastUpdatedAt: accessoryComplete ? lastUpdatedAt : 0,
        accessoryComplete: accessoryComplete
      }
      taskCardsCaches[scopeCard.id] = latestCache
    }
    return latestCache
  }

  static cache(scopeCard, cardCache, t) {
    const allCards = Object.values(cardCache)
    const unorderedTaskCards = {}

    allCards.forEach((card) => {
      const scopeCardId = card.shapeUp.scopeCardId
      if (scopeCardId == scopeCard.id) {
        unorderedTaskCards[card.id] = card
      }
    })

    return this.$cache(scopeCard, unorderedTaskCards, t)
  }

  static clearCache() {
    taskCardsCaches = {}
  }
}
